import React from "react"
import Container from "../../components/layout/Container"
import Layout from "../../components/layout/Layout"
import WideContainer from "../../components/layout/WideContainer"
import MetaData from "../../components/seo/MetaData"
import OrganizationStructuredData from "../../components/seo/OrganizationStructuredData"

interface Props {
  location: any
}

export default function BearSafetyPage({ location }: Props) {
  return (
    <Layout>
      <MetaData
        title="Bear safety in national parks"
        description="It's important to stay aware of wildlife in national parks. This is especially true for bears. Learn how to act in parks with bears."
        pageUrl={location.pathname}
      />
      <OrganizationStructuredData />
      <WideContainer center={true}>
        <div className={`container md:px-2 px-2`}>
          <h1 className="mt-6 md:mt-8 mb-8">Bear safety in national parks</h1>
        </div>
      </WideContainer>
      <Container center={true}>
        <p>
          Bears are amazing creatures and it's a great experience to see them in
          the wild. While the experience is great, you must remember they are
          wild animals and potentially dangerous. A bears behaviour can be
          unpredictable and you must therefore take precautions. Attacks on
          humans are generally rare but can occur and they often come with
          serious injury and potentially death.
        </p>
        <p>
          There is not a single strategy that will guarantee safety as all bears
          are unique. However, by following some simple guidelines you can
          minimize the danger. Most bear encounters are without any injuries.
        </p>
        <p>
          Be sure to check the latest safety information from the visitor center
          of the park you are visiting.
        </p>
        <h3>Bear encounter</h3>
        <h6>Identify yourself</h6>
        <p>
          You should calmly start talking so the bear knows you are a human and
          not a prey animal. You should remain still and stand your ground.
          Slowly raise your arms to make sure the bear sees you as a human.
          Sometimes bears will stand on their hind legs to get a better look.
          This indicates the bear is curious and not threatening.
        </p>
        <h6>Stay calm</h6>
        <p>
          The bears are usually not looking to attack. They are mostly just
          looking to be left alone. However, they do have different ways of
          reacting defensively. They might start charging and then turn away at
          the last second. There are other ways for the bear to act defensively
          such as woofing, salivating, growling and yawning. You should continue
          to talk to the bear in low tones as it won't threaten it. You should
          avoid sudden movements or high pitched screams or sounds. These could
          trigger an attack.
        </p>
        <h6>Pick up small children</h6>
        <p>
          Small children may not understand the seriousness of the situation.
        </p>
        <h6>Hike and travel in groups</h6>
        <p>
          A group is noisier than a single person. The bear will become aware of
          a group before it becomes aware of a single person because of noise
          and smell. A group is also larger in size and therefore more
          intimidating to the bear.
        </p>
        <h6>Make yourselves look as large as possible</h6>
        <p>
          One way to do this is to move yourself to a higher ground overlooking
          the bear.
        </p>
        <h6>Do NOT allow the bear access to your food</h6>
        <p>
          This indicates to the bear it will be able to get more food from
          humans. Please read more on how to store your food before entering an
          area with bears.
        </p>
        <h6>Do NOT drop your pack</h6>
        <p>A backpack can be used for protection in case of a bear attack.</p>
        <h6>If the bear is stationary, move away slowly and sideways</h6>
        <p>
          Moving sideways is less threatening to the bear. You will still be
          able to keep a close watch of the bear and make sure you don't trip.
          Do not run as bears will try to chase a fleeing animal or human. Bears
          can run extremely fast and they are able to climb trees. This is why
          you should never try to climb a tree. This goes for both black bears
          and brown bears.
        </p>
        <h6>Leave the area</h6>
        <p>
          You should try to leave the area. Sometimes you will be unable to
          leave the area. In this case, you should wait until the bear moves
          away. Make sure the bear has an escape route away from you.
        </p>
        <h6>Be especially cautious if you see a female with cubs</h6>
        <p>
          Never attempt to approach a bear cub as they are often accompanied by
          their mother. Never place yourself between a bear mother and her cubs.
          You are more likely to be attacked if the mother perceives you as a
          threat.
        </p>
        <h6>Bear Pepper Spray</h6>
        <p>
          Bear pepper spray can be an important safety measure in some parts of
          national parks. The pepper spray can be used in the same ways you use
          a regular pepper spray for humans. However, it is different spray.
          Bear pepper spray is designed for stopping a charging bear and it
          therefore stronger than a regular pepper spray. You can always
          investigate with the local national park centers whether or not a bear
          spray is recommended. If you decide to bring the psray, make sure you
          know how to use it. You can find several instructional videos online
          on this matter.
        </p>
      </Container>
    </Layout>
  )
}
